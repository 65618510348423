import React, { useState, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import './App.scss';
import {
  AppLayout,
  Route,
  Link,
  Icon,
  Sidebar,
  SidebarItem,
  SidebarSection,
  Topnav,
  SkipToContent,
  SkipToContentItem,
  FourOhFour,
} from '@monash/portal-react';
import {
  AppLauncherPad,
  EncumbranceNotification,
  MenuContext,
  UserMenu,
  portalLink,
  UserInitialsItem,
  UserInitialSection,
  ImpersonationContext,
  NotificationBadge,
  ChatBotContext,
  GenericError,
} from '@monash/portal-frontend-common';
import AllLinks from './components/pages/all-links/AllLinks';
import Home from './components/pages/home/Home';
import Search from './components/pages/search/Search';
import SearchBar from './components/utilities/search/SearchBar';
import Category from './components/pages/category/Category';
import PreviewPanel from './components/ui/preview-panel/PreviewPanel';
import PageNotFoundWrapper from './components/ui/page-not-found-wrapper/PageNotFoundWrapper';
import { USER_TYPE } from './constants/user-type';
import { categoryData } from './components/pages/category/data';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

const App = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const { menu, handleMenu, closeMenu, size, menuRef, getMenuRef } =
    useContext(MenuContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { ChatBotButton } = useContext(ChatBotContext);

  const paths = ['/', '/all-links'];
  const [selectedPageIndex, setSelectedPageIndex] = useState(
    paths.indexOf(window.location.pathname)
  );
  const mainRoutes = [
    { to: '/', component: Home, exact: true },
    { to: '/all-links', component: AllLinks },
    { to: '/search', component: Search },
    { to: '/preview', component: Home },
  ];

  const categoryPagePaths = categoryData.map((category) => category.path);

  // get user type for app launcher
  const userType = currentUser?.is?.MonashCollege
    ? USER_TYPE.COLLEGE
    : USER_TYPE.GENERAL;

  useEffect(() => {
    Sentry.setUser({ id: currentUser?.uid });
  }, []);

  return (
    <SentryErrorBoundary fallback={<GenericError />}>
      <SkipToContent>
        <SkipToContentItem text="Top navigation" skipTo="#topNav" />
        <SkipToContentItem text="Sidebar" skipTo="#sidebar" />
        <SkipToContentItem text="Content" skipTo="#contentContainer" />
      </SkipToContent>

      <AppLayout
        topnav={
          <Topnav
            title="Links"
            onMenu={handleMenu}
            size={size}
            getMenuRef={getMenuRef}
          >
            <SearchBar />

            <div>
              <NotificationBadge />
              <AppLauncherPad type={userType} />
              <ChatBotButton />
              <UserMenu getData={!!currentUser?.studentId}>
                <UserInitialSection>
                  <UserInitialsItem
                    id="profile"
                    href={portalLink('profile')}
                    text="View my profile"
                    icon={<Icon.Id />}
                  />
                  <UserInitialsItem
                    id="courseProgress"
                    href={portalLink('profile', 'course-progress')}
                    text="Course progress"
                    icon={<Icon.Ready />}
                  />
                </UserInitialSection>
              </UserMenu>
            </div>
          </Topnav>
        }
        sidebar={
          <Sidebar
            title="Links"
            ifMenu={menu}
            toggleMenu={handleMenu}
            closeMenu={closeMenu}
            size={size}
            menuRef={menuRef}
            selected={selectedPageIndex}
            onChange={setSelectedPageIndex}
          >
            <SidebarSection aria-label="Menu">
              <SidebarItem
                linkTo="/"
                id="tab-home"
                text="Categories"
                icon={<Icon.Layout2 />}
              />
              <SidebarItem
                linkTo="/all-links"
                id="tab-all-links"
                text="All links"
                icon={<Icon.LayoutList />}
              />
            </SidebarSection>
          </Sidebar>
        }
        content={
          <>
            {currentUser?.hasEncumbrances && <EncumbranceNotification />}

            {mainRoutes.map(({ component: Component, ...routeProps }) => (
              <Route {...routeProps} key={routeProps.to}>
                <SentryErrorBoundary fallback={<GenericError />}>
                  <Component />
                </SentryErrorBoundary>
              </Route>
            ))}

            {categoryPagePaths.map((page, i) => (
              <Route to={page} key={page} exact>
                <SentryErrorBoundary fallback={<GenericError />}>
                  <Category content={categoryData[i]} heroIndex={i} />
                </SentryErrorBoundary>
              </Route>
            ))}

            <PreviewPanel />

            <FourOhFour>
              <PageNotFoundWrapper
                setShowSidebar={setShowSidebar}
                action={<Link to="/">Go to Links</Link>}
              />
            </FourOhFour>
          </>
        }
        showSidebar={showSidebar}
      />
    </SentryErrorBoundary>
  );
};

export default App;
