import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  AuthProvider,
  LoadingScreen,
  ImpersonationWrapper,
  APIProvider,
  MegaWrapper,
  MenuProvider,
  accessControl,
  FeatureProvider,
  NotificationProvider,
  AccessibilityProvider,
  ChatBotWrapper,
} from '@monash/portal-frontend-common';
import homeLoading from './assets/lotties/home-loading.json';
import { Router } from '@monash/portal-react';
import SearchProvider from './components/providers/search-provider/SearchProvider';
import initMonitoring from './initMonitoring';
import { FEATURE_FLAGS } from './constants/features';

const env = process.env.REACT_APP_ENV;
if (env !== 'local') {
  initMonitoring(env);
}

const container = document.getElementById('root');
const root = createRoot(container);

const loadingScreen = <LoadingScreen animation={homeLoading} />;

const wrappers = [
  {
    comp: AuthProvider,
    props: {
      nolanding: true,
      loadingPage: loadingScreen,
      accessControl: accessControl.isStudent,
      getPreferences: true,
    },
  },
  {
    comp: FeatureProvider,
    props: {
      defaultFeatureFlags: FEATURE_FLAGS,
    },
  },
  React.StrictMode,
  APIProvider,
  ImpersonationWrapper,
  ChatBotWrapper,
  MenuProvider,
  AccessibilityProvider,
  Router,
  SearchProvider,
  NotificationProvider,
];

root.render(
  <MegaWrapper providers={wrappers}>
    <App />
  </MegaWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
